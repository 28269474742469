<template>
  <div>
    <b-button-close data-test="organisationCreation-cancel" @click="$emit('cancel')" class="close"/>
    <h6>{{ $t('organisationCreation.title') }}</h6>
    <br>
    <form>

      <!-- NAME -->
      <div
        v-if="isFieldVisible('name', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-11">
          <ValidationProvider
            :rules="isFieldRequired('name', formConfig.requiredFields)"
            v-slot="{ classes, errors }"
          >
            <div class="control" :class="classes">
              <label
                :class="isFieldRequired('name', formConfig.requiredFields)"
                for="organisationCreation-name"
              >
                {{ $t('organisationCreation.form.name') }}
              </label>
              <p>{{ $t('organisationCreation.form.nameHelp') }}</p>
              <input
                v-model="formData.name"
                id="organisationCreation-name"
                data-test="organisationCreation-name"
                class="form-control"
                type="text"
                placeholder=""
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>

      <!-- ACRONYM -->
      <div
        v-if="isFieldVisible('acronym', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <div class="control">
            <label for="organisationCreation-sigle">
              {{ $t('organisationCreation.form.acronym') }}
            </label>
            <input
              v-model="formData.sigle"
              id="organisationCreation-sigle"
              data-test="organisationCreation-sigle"
              class="form-control"
              type="text"
              placeholder=""
            >
          </div>
        </div>
      </div>

      <!-- SIRET -->
      <div
        v-if="isFieldVisible('siret', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label for="organisationCreation-siret">
            {{ $t('organisationCreation.form.siret') }}
          </label>
          <input
            v-model="formData.siret"
            id="organisationCreation-siret"
            data-test="organisationCreation-siret"
            class="form-control"
            type="text"
            placeholder="xxx xxx xxx xxxxx"
          >
        </div>
      </div>

      <!-- TYPE -->
      <div
        v-if="isFieldVisible('type', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <ValidationProvider
            :rules="isFieldRequired('type', formConfig.requiredFields)"
            v-slot="{ classes, errors }"
          >
            <div class="control" :class="classes">
              <label
                :class="isFieldRequired('type', formConfig.requiredFields)"
                for="organisationCreation-type"
              >
                {{ $t('organisationCreation.form.type') }}
              </label>
              <Multiselect
                v-model="formData.type"
                :options="organisationsTypes"
                id="organisationCreation-type"
                data-test="organisationCreation-type"
                track-by="codename"
                label="display_name"
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                :searchable="false"
                :placeholder="$t('organisationCreation.form.typePlaceholder')"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>

      <!-- LOGO -->
      <div
        v-if="isFieldVisible('logo', formConfig.hiddenFields)"
        class="form-row"
      >
        <div class="form-group col-6">
          <label for="organisationCreation-logo">
            {{ $t('organisationCreation.form.logo') }}
          </label>
          <p>{{ $t('organisationCreation.form.logoHelp') }}</p>
          <ValidationProvider ref="thumbnail" v-slot="{ classes, errors }">
            <div class="control" :class="classes">
              <ImportImage
                id="organisationCreation-logo"
                name="logo"
                :title="null"
                :file="null"
                @update="setThumbnail"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>

      <!-- WEBSITE -->
      <div class="form-row">
        <div class="form-group col-9">
          <label for="organisationCreation-web">
            {{ $t('organisationCreation.form.web') }}
          </label>
          <input
            v-model="formData.web"
            id="organisationCreation-web"
            data-test="organisationCreation-web"
            class="form-control"
            type="text"
            placeholder="https://"
            @focus="formData.web === null ? formData.web = 'https://' : null"
            @blur="formData.web === 'https://' ? formData.web = null : null"
          >
        </div>
      </div>

      <!-- PHONE -->
      <div class="form-row">
        <div class="form-group col-6">
          <label for="organisationCreation-tel">
            {{ $t('organisationCreation.form.phone') }}
          </label>
          <ValidationProvider
            ref="phone_number"
            :rules="isFieldRequired('phone_number', formConfig.requiredFields)"
            v-slot="{ classes, errors }"
          >
            <div class="control" :class="classes">
              <input
                v-model="formData.tel"
                id="organisationCreation-tel"
                data-test="organisationCreation-tel"
                class="form-control"
                type="text"
                placeholder=""
              >
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>

      <!-- ADDRESS -->
      <div class="form-row">
        <div class="form-group col-11">
          <label for="organisationCreation-postalAddress">
            {{ $t('organisationCreation.form.address') }}
          </label>
          <textarea
            v-model="formData.postalAddress"
            id="organisationCreation-postalAddress"
            data-test="organisationCreation-postalAddress"
            class="form-control"
          />
        </div>
      </div>

      <!-- DESCRIPTION -->
      <div class="form-row">
        <div class="form-group col-11">
          <label for="organisationCreation-description">
            {{ $t('organisationCreation.form.description') }}
          </label>
          <textarea
            v-model="formData.description"
            id="organisationCreation-description"
            data-test="organisationCreation-description"
            class="form-control"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-11">
          <label for="organisationCreation-groupOfOrganisation">
            {{ $t('organisationCreation.form.group.title') }}
          </label>
          <p>
            {{ $t('organisationCreation.form.group.help') }}
          </p>
          <SearchUsergroups
            id="organisationCreation-groupOfOrganisation"
            :type="'group-of-organisation'"
            :placeholder="$t('organisationCreation.form.group.placeholder')"
            @select="addOrgToSphere"
          />
          <div
            v-if="spheres.length > 0"
            id="orga-spheres-container"
          >
            <div
              v-for="sphere of spheres"
              :key="sphere.id"
              class="orga-sphere"
            >
              {{ sphere.display_name }}
              <b-icon-x
                font-scale="1.5"
                data-test="organisationCreation-removeOrgFromSphere" 
                @click="removeOrgFromSphere"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr class="divider" />
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex';

import i18n from '@/i18n';

import ImportImage from '@/components/ImportImage';
import SearchUsergroups from '@/components/SearchUsergroups';

import {
  ValidationProvider,
  extend,
  configure
} from 'vee-validate';
import {
  required
} from 'vee-validate/dist/rules';


extend('required', {
  ...required,
  message: () => i18n.t('errors.required')
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'OrganisationCreation',

  components: {
    ValidationProvider,
    ImportImage,
    SearchUsergroups
  },

  data() {
    return {
      formData: {
        name: null,
        sigle: null,
        siret: null,
        type: null,
        web: null,
        postalAddress: null,
        tel: null,
        description: null
      },
      thumbnail: null,
      spheres: []
    };
  },

  computed: {
    ...mapState('organisations', [
      'organisationsTypes'
    ]),
    ...mapState('sign-up', [
      'error'
    ]),

    formConfig() {
      return this.$config.forms.organisation;
    },
  },

  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        this.$emit('update', {
          form: newValue,
          thumbnail: this.thumbnail,
          spheres: this.spheres
        });
      }
    },
    thumbnail(newValue) {
      this.$emit('update', {
        form: this.formData,
        thumbnail: newValue,
        spheres: this.spheres
      });
    },
    spheres: {
      deep: true,
      handler(newValue) {
        this.$emit('update', {
          form: this.formData,
          thumbnail: this.thumbnail,
          spheres: newValue
        });
      }
    },
    error(newValue) {
      if (newValue && newValue.usergroup_roles && newValue.usergroup_roles.length && newValue.usergroup_roles[0].organisation) {
        for (const [key, value] of Object.entries(newValue.usergroup_roles[0].organisation)) {
          if (this.$refs[key]) {
            this.$refs[key].applyResult({
              errors: value,
              valid: false,
              failedRules: {},
            });
          }
        }
      }
    }
  },

  created() {
    if (this.organisationsTypes.length === 0) {
      this.GET_ORGANISATIONS_TYPES();
    }
    this.$emit('update', {
      form: this.formData,
      thumbnail: this.thumbnail,
      spheres: this.spheres
    });
  },

  methods: {
    ...mapActions('organisations', ['GET_ORGANISATIONS_TYPES']),

    setThumbnail(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.thumbnail = formData;
    },

    addOrgToSphere(e) {
      this.spheres.push(e);
    },
    removeOrgFromSphere(e) {
      const index = this.spheres.findIndex(el => el.id === e.id);
      this.spheres.splice(index, 1);
    }
  }
}
</script>

<style lang="less" scoped>
form {
  min-width: 800px;
  margin-bottom: 3em;
}

h2 {
  color: @blue;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

button {
  margin: 1em 2em 0 0;
  font-size: 1em;
}

.close {
  font-size: 2rem;
  position: relative;
  display: block;
  float: right;
  top: -2.5rem;
  right: -2.5rem;
}

#orga-spheres-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0.1em;
  margin: 0.5em 0;
  .orga-sphere {
    display: flex;
    margin: 0.2em 1em 0.2em 0;
    padding: 0.5em;
    border-radius: 10px;
    font-size: 0.9em;
    background-color: @blue;
    color: white;
    .b-icon {
      cursor: pointer;
    }
  }
}

</style>
