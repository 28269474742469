<template>
  <div :class="{disabled: disabled}">
    <img
      :id="previewId"
      :hidden="!filePreview"
      style="max-width: 400px; margin: 1em 1em 0.3em;"
      alt="Image preview"
    /><br v-if="filePreview">
    <div v-if="fileExist || filePreview">
      <div v-if="fileExist && !filePreview">
        <img
          :src="fileURL"
          style="max-width: 400px; margin: 1em 1em 0.3em;"
          alt="Image preview"
        />
        <p style="margin: 0 0 0.5em 1.3em">
          {{ title.split('/').slice(-1)[0] }}
        </p>
      </div>
    </div>
    <div
      v-else
      id="import-file"
      data-test="importImage-uploadFile"
      @click="uploadFile"
    >
      <img
        src="@/assets/icons/file_document_sheet.svg"
        alt="File icon"
      />
      <div v-if="type === 'logo'"><b-icon-plus />{{ $t('importImage.logo') }}</div>
      <div v-if="type === 'image'"><b-icon-plus />{{ $t('importImage.image') }}</div>
    </div>
    <div>
      <input
        :id="`upload-file-${this.name}`"
        :hidden="!(fileExist || filePreview)"
        type="file"
        accept="image/*"
        placeholder="$t('importImage.placeholder')"
        data-test="importImage-previewFile"
        @change="previewFile"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportImage',

  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'logo'
    },
    file: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      fileExist: false,
      filePreview: false
    }
  },

  computed: {
    previewId() {
      return `preview-file-${this.name}`;
    },
    fileURL() {
      if (this.file) {
        return `${process.env.VUE_APP_DOMAIN}${this.file}`;
      } else {
        return ''
      }
    }
  },

  watch: {
    accept(newValue, oldValue) {
      if (newValue || (!newValue && oldValue)) {
        this.filePreview = false;
      }
    }
  },

  created() {
    if (this.file) {
      this.fileExist = true;
    }
  },

  methods: {
    uploadFile() {
      const elem = document.getElementById(`upload-file-${this.name}`);
      elem.click();
    },
    previewFile(e) {
      const previewEl = document.getElementById(this.previewId);
      if (e.target.files && e.target.files[0]) {
        this.fileExist = false;
        this.filePreview = true;
        previewEl.src = URL.createObjectURL(e.target.files[0]);
        previewEl.onload = function() {
          URL.revokeObjectURL(previewEl.src) // free memory
        }
        this.$emit('update', e.target.files[0]);
      }
    }
  }
}
</script>

<style lang="less" scoped>

#import-file {
  cursor: pointer;
  margin-top: 0.5em;
  border: 1px dashed @blue;
  border-radius: 3px;
  width: 240px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  img {
    height: 2.5em;
  }
  div {
    color: @blue;
    font-size: 1.1em;
    font-weight: bold;
    font-style: normal;
  }
}
#import-file:hover {
  border: 2px dashed @blue;
  img {
    height: 2.7em;
  }
  div {
    font-size: 1.3em;

  }
}

#preview-file {
  max-width: 15em;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

</style>
