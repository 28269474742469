<template>
  <div>
    <Multiselect data-test="searchUsergroups-selection"
      v-model="selection"
      style="margin-top: 0.5em;"
      class="search-usergroups"
      :options="results"
      :optionsLimit="10"
      :allow-empty="false"
      track-by="id"
      label="display_name"
      :resetAfter="true"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      :searchable="true"
      :placeholder="placeholder"
      :showNoResults="true"
      :loading="loading"
      :clearOnSelect="false"
      :preserveSearch="true"
      @search-change="search"
      @select="select"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          data-test="searchUsergroups-removeSelection"
          @click.prevent.stop="selection = null"
        >
          <b-icon-x font-scale="2"/>
        </div>
      </template>
      <span slot="noResult">
        {{ $t('multiselect.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('multiselect.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchUsergroups',

  components: {
    Multiselect
  },

  props: {
    placeholder: {
      type: String,
      default: 'Ajouter cette organisation à des groupes d\'organisations'
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('usergroups', [
      'spheres'
    ])
  },

  watch: {
    text: function(newValue) {
      this.loading = true;
      this.SEARCH_SPHERES(newValue)
      .then(() => {
        if (newValue) {
          this.results = this.spheres;
        } else {
          // this.results.splice(0);
          this.results = this.spheres;
        }
        this.loading = false;
      });
    }
  },

  created() {
    this.GET_SPHERES_LIST()
    .then(() => {
      this.results = this.spheres;
    })
  },

  methods: {
    ...mapActions('usergroups', [
      'GET_SPHERES_LIST',
      'SEARCH_SPHERES'
    ]),

    search(text) {
      this.text = text;
    },

    select(e) {
      this.$emit('select', e);
      this.selection = null;
    }
  }
}
</script>
