<template>
  <div>
    <h5>{{ $tc('signup.form.organisation.title') }}</h5>
    <hr class="divider" />
    <div v-if="!showCreationForm">
      <label for="organisationSelector-organisation">
        {{ $tc('signup.form.organisation.subtitle') }}
      </label>
      <div class="infos">
        {{ $tc('signup.form.organisation.info') }}
      </div>
      <Multiselect
        id="organisationSelector-organisation"
        data-test="organisationSelector-organisation"
        v-model="organisation"
        style="margin-top: 0.5em;"
        class="search-usergroups"
        :options="suggestions.local"
        :optionsLimit="10"
        :allow-empty="false"
        track-by="id"
        label="display_name"
        :resetAfter="false"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        :searchable="true"
        :placeholder="$tc('signup.form.organisation.placeholder')"
        :showNoResults="true"
        :loading="loading"
        :clearOnSelect="false"
        :preserveSearch="false"
        @search-change="search"
      >
        <template slot="clear">
          <div
            v-if="organisation"
            class="multiselect__clear"
            data-test="organisationSelector-removeOrganisation"
            @click.prevent.stop="organisation = null"
          >
            <b-icon-x font-scale="2"/>
          </div>
        </template>
        <span slot="noResult">
          {{ $t('multiselect.noResult') }}
        </span>
        <span slot="noOptions">
          {{ $t('signup.form.organisation.noOptions') }}
        </span>
      </Multiselect>
      <label>
        {{ $t('signup.form.organisation.help.text') }}
        <a
          data-test="organisationSelector-showCreationFormTrue"
          @click="showCreationForm = true"
        >
          {{ $t('signup.form.organisation.help.link') }}
        </a>
      </label>
    </div>
    <OrganisationCreation
      v-if="showCreationForm"
      data-test="organisationSelector-showCreationFormFalse"
      @update="updateOrganisationToCreate"
      @cancel="showCreationForm = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';

import OrganisationCreation from '@/components/OrganisationCreation.vue';

export default {
  name: 'OrganisationSelector',
  components: {
    OrganisationCreation
  },
  data() {
    return {
      loading: false,
      text: null,
      suggestions: {
        local: []
      },
      results: [],
      organisation: null,
      role: null,
      showCreationForm: false,
      organisationToCreate: {
        codename: null,
        display_name: null,
        description: null,
        organisation_type: null,
        registration_number: null,
        phone_number: null,
        website_url: null,
        postal_address: null,
      },
      organisationThumbnail: null,
      organisationSpheres: [],
      selectedOrganisation: null,
    };
  },
  computed: {
    ...mapState('organisations', [
      'organisationsList',
      'organisationsRoles'
    ])
  },
  watch: {
    text(newValue) {
      this.suggestions.search(newValue, data => {
        this.results = data.map(function(item) {
          return item.id;
        });
      });
    },

    organisationsList: {
      deep: true,
      handler(newValue) {
        const SuggestionsEngine = Bloodhound.noConflict();
        let local = [];
        newValue.map(function(item) {
          local.push({
            id: item.id,
            display_name: item.display_name
          });
        });
        this.suggestions = new SuggestionsEngine({
          local: local,
          initialize: true,
          datumTokenizer: Bloodhound.tokenizers.obj.nonword('title'),
          queryTokenizer: Bloodhound.tokenizers.nonword
        });
      }
    },

    organisation(newValue) {
      if (newValue) {
        this.selectedOrganisation = newValue;
      }
    },
    organisationToCreate: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.selectedOrganisation = newValue;
        }
      }
    },
    organisationThumbnail(newValue) {
      if (newValue) {
        this.$emit('select', {
          selected: true,
          orga: this.selectedOrganisation,
          thumbnail: newValue,
          spheres: this.organisationSpheres
        });
      }
    },
    organisationSpheres: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.$emit('select', {
            selected: true,
            orga: this.selectedOrganisation,
            thumbnail: this.organisationThumbnail,
            spheres: newValue
          });
        }
      }
    },
    selectedOrganisation(newValue) {
      if (newValue) {
        this.$emit('select', {
          selected: true,
          orga: newValue,
          thumbnail: this.organisationThumbnail,
          spheres: this.organisationSpheres
        });
      }
    },
  },

  created() {
    this.GET_ORGANISATIONS_LIST();
  },

  methods: {
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST',
      'GET_ORGANISATIONS_ROLES',
    ]),

    search(text) {
      this.text = text;
    },

    updateOrganisationToCreate(e) {
      this.organisationToCreate.codename =
        e.form.name ?
          e.form.name.normalize('NFD').replace(/[\u0300-\u036f]|[^A-Z0-9]/ig, '').toLowerCase() :
          null;
      this.organisationToCreate.acronym = e.form.sigle;
      this.organisationToCreate.display_name = e.form.name;
      this.organisationToCreate.description = e.form.description;
      this.organisationToCreate.organisation_type = e.form.type;
      this.organisationToCreate.registration_number = e.form.siret;
      this.organisationToCreate.phone_number = e.form.tel;
      this.organisationToCreate.website_url = e.form.web;
      this.organisationToCreate.postal_address = e.form.postalAddress;

      this.organisationSpheres = e.spheres;
      this.organisationThumbnail = e.thumbnail;
    },
  },
};
</script>

<style lang="less" scoped>
label {
  font-size: 1rem;
  font-weight: normal;
  a {
    color: #105182 !important;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}
.infos {
  font-size: 0.8em;
  font-style: italic;
  margin-right: 1em;
}
</style>
